


























































































































































































































































































































































































































































































































































.filter {
  &-title {
    margin-bottom: 12px;
    font-family: "Raleway Medium", serif;
    font-size: 16px;
    color: #000000;
  }

  &-select::v-deep {
    width: 100%;

    input {
      color: #090a0a;
      border-radius: 8px;
      height: 48px;
    }
  }
}

.el-form-item {
  margin-bottom: 22px;
  text-align: left;
}
